<template>
  <div class="pressCenter">
    <CompanyProfileBanner :index="'5'" :imageUrl="imageUrl"></CompanyProfileBanner>
    <BreadcrumbAndSubtag
      :tabList="tabList"
      :activeNowPath="activeNowPath"
      @changeActiveTabFun="changeActiveTabFun"
    ></BreadcrumbAndSubtag>
    <div class="newsContent">
      <div class="contents">
        <div class="newsList">
          <ul>
            <li
              v-for="(item,index) in newsList.slice((currPage-1)*pageSize,currPage*pageSize)"
              :key="index"
              @click="handleClickNewsListFun(item)"
            >
              <div class="newsBox">
                <div class="newsImg">
                  <img v-if="item.imageThumbnail" :src="item.imageThumbnail" />
                  <el-empty v-else description=" " :image-size="140"></el-empty>
                </div>
                <div class="sketch">
                  <h4>{{ item.title }}</h4>
                  <div>{{ item.time }}</div>
                  <p>{{ item.texts }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="newsList.length"
        ></el-pagination>
      </div>
    </div>
    <footerMain></footerMain>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Secret from "@/utils/secret";
import footerMain from '@/components/footerMain'
import CompanyProfileBanner from '@/components/companyProfileBanner'
import BreadcrumbAndSubtag from '@/components/breadcrumbAndSubtag'

export default {
  name: "pressCenter",
  data() {
    return {
      imageUrl: '',
      tabList: [
        {key: '6-1', name: '公司新闻'},
        {key: '6-2', name: '行业新闻'},
      ],
      activeNowPath: '',
      newsList: [],
      pageSize: 5,
      currPage: 1,
    }
  },
  components: {
    CompanyProfileBanner,
    BreadcrumbAndSubtag,
    footerMain
  },
  computed: {
    ...mapGetters(['secondaryRoutingIndex'])
  },
  watch: {
    secondaryRoutingIndex: {
      handler(n,o) {
        const checkPath = (url) => {
          return [
            "6-1",
            "6-2",
          ].includes(url);
        }
        if(checkPath(n)) {
          this.activeNowPath = n
        } else {
          this.activeNowPath = '6-1'
          this.$store.commit('SET_CURRENT_ROUTE', '/pressCenter')
          this.$store.commit('SET_SECONDARY_ROUTING_INDEX', '6-1')
        }
      },
      immediate: true
    },
  },
  created() {
    this.loadResourceOfPageFun()
    this.getArticleListFun()
  },
  methods: {
    changeActiveTabFun(val) {
      this.activeNowPath = val
      this.getArticleListFun()
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', val)
    },
    handleSizeChange(val) { // 处理每页显示条数的变化
      this.pageSize = val;
    },
    handleCurrentChange(val) {// 处理当前页面的变化
      this.currPage = val;
    },
    loadResourceOfPageFun() {
      api.getResourceOfPage({pageId: 15, locationType: 1}).then((res) => {
        if(res.code === 100) {
          this.imageUrl = resAddressPrefix + res.data[0].images[0].imageUrl
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    getArticleListFun() {
      const _params = {
        pageId: this.activeNowPath ? this.loadListDataFun(this.activeNowPath) : 16
      }
      api.getArticleList(_params).then((res) => {
        if(res.code === 100) {
          const _data = res.data[0].articles
          _data.forEach(e => {
            let _texts = e.content.replace(/<[^>]+>/g, '')
            e['time'] = Secret.ConvertToDateFun(e.createTime, 'yyyy-MM-dd');
            e['texts'] = _texts.replace(/&nbsp;/g, '');
          });
          this.currPage = 1
          this.newsList = _data
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    loadListDataFun(key) {
      switch (key) {
        case '6-1':
          return 16;
        case '6-2':
          return 17;
        default:
          return 16;
      }
    },
    handleClickNewsListFun(item) {
      this.$store.commit('SET_ARTICLES_ID', item.id)
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', this.activeNowPath)
      this.$router.push({
        path: '/pressCenterDetails',
        query: {data: btoa(encodeURIComponent(JSON.stringify({type: 'news', pageId: this.loadListDataFun(this.activeNowPath)}))),
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.pressCenter {
  .newsContent {
    margin: auto;
    display: table;
    vertical-align: middle;
    .contents {
      width: 1200px;
      height: 560px;
      padding: 4em 0;
      margin: auto;
      display: table-cell;
      vertical-align: middle;
      .newsList {
        width: 100%;
        height: 100%;
        ul {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            .newsBox {
              overflow: hidden;
              text-align: left;
              border-bottom: 1px dashed rgba(184, 184, 184, 1);
              padding-bottom: 20px;
              margin-bottom: 20px;
              .newsImg {
                width: 270px;
                height: 170px;
                padding: 0 15px;
                cursor: pointer;
                display: inline-block;
                vertical-align: middle;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 4px;
                }
              }
              .sketch {
                width: calc(100% - 320px);
                padding-left: 20px;
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                h4 {
                  font-size: 18px;
                  padding: 10px 0;
                  margin: 0;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: block;
                  cursor: pointer;
                }
                div {
                  color: #b6b6b6;
                  font-size: 13px;
                  padding-bottom: 10px;
                }
                p {
                  color: #929292;
                  margin: 0;
                  font-size: 16px;
                  line-height: 1.6;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  word-break: break-all;
                  height: 52px;
                }
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .el-empty {
    margin: 0;
    padding: 0;
    border-radius: 4px;
    border: 1px solid rgba(184, 184, 184, 1);
  }
}
</style>
